import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState/EmptyState"
import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import ColoredSensorSvg from "src/ui/icons/marketing-system-icons/sensor-colored.svg"
import { spacing } from "src/ui/spacing"

import HomeEventsEmptyStateImage from "./images/home-events-empty-state.svg"
import { AppStoreLogos } from "./AppStoreLogos"

export function NoEventsLegacy() {
  return (
    <EmptyState
      icon={<ColoredLargeSensorSvg />}
      title="You have not received any events yet"
      body={
        <Grid>
          Add a sensor from the Minut mobile app and events will show up here.
          <AppStoreLogos />
        </Grid>
      }
    />
  )
}

// Will replace `NoEventsLegacy` once we remove legacy event logs
export function NoEvents() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={HomeEventsEmptyStateImage}
      title={t(langKeys.events_empty_state_title)}
      description={t(langKeys.events_empty_state_description)}
      content={<AppStoreLogos />}
    />
  )
}

const ColoredLargeSensorSvg = styled(ColoredSensorSvg)`
  width: 150px;
  height: 150px;
`

const Grid = styled.div`
  display: grid;
  place-items: center;
  gap: ${spacing.M};
`
